import React from 'react';
import {
  Colors,
  Icon,
  IconName,
  Text,
  Typography,
} from 'goalsetter-webapp-components';
import { Trans, useTranslation } from 'react-i18next';
import Images from 'assets/img/Images';
import { Flex, Image } from '@mantine/core';
import {
  StyledBoldText,
  StyledModal,
  closeButtonStyle,
  innerContainerStyle,
  textAlignCenterStyle,
  verticalPaddingStyle,
} from './styles';
import DownloadHeaderImg from 'assets/img/downloadHeaderImg';

interface DownloadAppModalProps {
  show: boolean;
  onClose: () => void;
}

const DownloadAppModal: React.FC<DownloadAppModalProps> = ({
  show,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <StyledModal
      opened={show}
      centered
      onClose={onClose}
      withCloseButton={false}
      closeOnClickOutside={false}
      closeOnEscape={false}
      padding={32}
      radius={20}
    >
      <Icon
        icon={IconName.CLOSE}
        size={48}
        color={Colors.ORANGE}
        onClick={onClose}
        style={closeButtonStyle}
      />
      <Flex
        direction="column"
        justify="center"
        align="center"
        style={innerContainerStyle}
      >
        <DownloadHeaderImg />
        <Flex
          direction="column"
          justify="center"
          align="center"
          style={verticalPaddingStyle}
        >
          <Text
            type={Typography.GSH5}
            style={textAlignCenterStyle}
          >
            {t('downloadAppModal.title')}
          </Text>
          <Text
            type={Typography.GSBODY1}
            style={textAlignCenterStyle}
          >
            <Trans
              i18nKey={t('downloadAppModal.subtitle')}
              components={{
                span: <StyledBoldText />,
              }}
            />
          </Text>
        </Flex>
        <Flex style={verticalPaddingStyle}>
          <Image
            src={Images.QR}
            width={200}
          />
        </Flex>
      </Flex>
    </StyledModal>
  );
};

export default DownloadAppModal;
