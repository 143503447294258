import { useState } from 'react';
import { AxiosPromise, AxiosError } from 'axios';

interface FetchDataResponse<TData, TError> {
  data: TData | null;
  loading: boolean;
  error: TError | null;
  fetchData: (promise: AxiosPromise<TData>) => void;
}

const useFetchData = <TData, TError>(): FetchDataResponse<TData, TError> => {
  const [data, setData] = useState<TData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<TError | null>(null);

  const fetchData = async (promise: AxiosPromise<TData>) => {
    setLoading(true);
    promise
      .then((response) => {
        setData(response as TData);
        setError(null);
      })
      .catch((error: AxiosError) => {
        const gsError = error.response?.data as {
          'status-code': number;
          'status-description': string;
        };

        if (gsError) {
          const newError = {
            'status-code': gsError['status-code'] || error?.code,
            'status-description':
              gsError['status-description'] || error?.message,
          };
          return setError(newError as TError);
        }
        return setError({
          'status-code': error?.code,
          'status-description': error?.message,
        } as TError);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { data, loading, error, fetchData };
};

export default useFetchData;
