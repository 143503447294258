// eslint-disable-next-line import/no-anonymous-default-export
export default {
  translation: {
    continue: '(ES) CONTINUE',
    maybeLater: '(ES) MAYBE LATER',
    signUpButton: '(ES) SIGN UP',
    logInButton: '(ES) LOG IN',
    uhOh: '(ES) Uh Oh!',
    tryAgain: '(ES) Sorry! Try Again',
    retry: '(ES) RETRY',
    skipForNow: '(ES) SKIP FOR NOW',
    gotIt: '(ES) GOT IT!',
    leave: '(ES) LEAVE',
    stay: '(ES) STAY ON SCREEN',
    leaveFormModalDescription:
      '(ES) If you leave this screen you will lose the data you entered and you will have to start again',
    recoverPassword: {
      title: '(ES) Recover your password',
      description:
        '(ES) To recover your password, please enter your email address or phone number. Reset instructions will be sent to you.',
      formInputs: {
        username: {
          label: '(ES) Email Address or Phone Number',
          placeholder: '(ES) Ej: hello@goalsetter.co or (999) 999 9999',
        },
      },
      errors: {
        tryAgain: {
          message:
            '(ES) We are sorry, we could not send the instructions. Please try again.',
        },
      },
      formErrors: {
        username: {
          wrong: '(ES) Enter a valid Email Address or Phone Number',
        },
      },
      recoverPasswordModal: {
        title: '(ES) Recover your Password',
        description:
          '(ES) If our records match the email or phone number provided, you ’ll receive an email or SMS with reset instructions. For help, please email us at',

        buttonLabel: '(ES) GO TO LOGIN',
        contactEmail: '(ES) hello@goalsetter.co',
      },
    },
    notAvailable: {
      title: '(ES) Thank you for choosing Goalsetter',
      description:
        '(ES) At the moment, your account can only be used in the app. Sorry for the inconvenience.',
      downloadTheApp: '(ES) DOWNLOAD THE APP',
    },
    sendGift: {
      title: '(ES) Who’s the lucky person?',
      subtitle:
        '(ES) The Goalsetter® user´s exact Profile ID must be used, which they can find in their profile.',
      inviteLabel: '(ES) Is your lucky person not a Goalsetter® user?',
      inviteButtonLabel: '(ES) INVITE THEM!',
      notFound:
        '(ES) We did not find any user with that profile name. If the person you´re looking for doesn´t have a Goalsetter® account, you can invite them to join.',
      input: {
        placeholder: '(ES) Enter profile name',
      },
      userProfileNotFound: {
        title: '(ES) User Not Found',
        subtitle:
          '(ES) We did not find that profile. Please search for the user with their profile name.',
      },
    },
    footer: {
      about: '(ES) ABOUT',
      contactUs: '(ES) CONTACT US',
      goalCardTerms: '(ES) GOALCARD® TERMS OF USE',
      rights:
        '© (ES) 2022 All Rights Reserved. Goalsetter® is a registered trademark of Students of Wealth, Inc.',
    },
    downloadApp: {
      downloadAppButton: '(ES) DOWNLOAD',
      downloadApp:
        '(ES) Download the Goalsetter® app now and enjoy a free 30-day trial.',
      scanQr:
        '(ES) Scan the QR code to download the Goalsetter® app now and enjoy a free 30-day trial.',
    },
    sendInvitation: {
      title:
        '(ES) <parent>Invite to join Goalsetter<span>{{reg}}</span> !</parent>',
      sendInvitation: '(ES) SEND INVITATION',
      formInputs: {
        yourName: {
          label: '(ES) Your Name',
          placeholder: '(ES) Enter your name',
        },
        theirName: {
          label: '(ES) Their Name',
          placeholder: '(ES) Enter the person you want to invite',
        },
        contact: {
          label: '(ES) Email Address or Phone Number',
          placeholder:
            '(ES) Enter Email Address or Phone Number of the person you want to invite',
        },
      },
      googleSupport: '(ES) This site is protected by reCAPTCHA and the Google ',
      privacyPolicy: '(ES) Privacy Policy',
      and: '(ES) and ',
      termsService: '(ES) Terms of Services apply.',
      share: {
        title: '(ES) Start making smarter money moves!',
        text: '(ES) Use this link to download the Goalsetter app',
        url: '(ES) https://devgoalsetter.page.link/mhUkjDBmEHsJD9PE8',
      },
      formErrors: {
        yourName: {
          empty: '(ES) Enter your name',
          wrong: '(ES) Can only contain letters',
        },
        theirName: {
          empty: '(ES) Enter the person you want to invite',
          wrong: '(ES) Can only contain letters',
        },
        contact: {
          empty:
            '(ES) Enter Email Address or Phone Number of the person you want to invite',
          wrong: '(ES) Enter a valid Email Address or Phone Number',
        },
      },
      error: '(ES) The invitation could not be sent, please try again.',
    },
    sentSuccess: {
      title: '(ES) Invitation sent!',
      description:
        '(ES) We have sent an invitation to {{destinationName}} to join Goalsetter®. Once an account is created you will be able to send them a GoalCard®.',
      description_two:
        '(ES) In the meantime, you can send a GoalCard® to any Goalsetter® user you know.',
      sendGoalcard: '(ES) Send a goalcard®',
    },
    selectCard: {
      title: '(ES) Let´s customize {{name}}´s GoalCard<span>{{reg}}</span>',
      description: '(ES) Choose your GoalCard<span>{{reg}}</span> design',
      filterOptions: [
        {
          id: 0,
          value: 'all',
          label: '(ES) All GoalCards®',
          icon: 'all',
        },
        {
          id: 1,
          value: 'birthday',
          label: '(ES) Birthday',
          icon: 'birthday',
        },
        {
          id: 2,
          value: 'holiday',
          label: '(ES) Holiday',
          icon: 'holiday',
        },
        {
          id: 3,
          value: 'milestone_moments',
          label: '(ES) Milestone',
          icon: 'flag',
        },
        {
          id: 4,
          value: 'just_because',
          label: '(ES) Just Because',
          icon: 'heart',
        },
      ],
      errorModalDescription:
        '(ES) We´re having trouble connecting with your device. Please try again.',
    },
    customizeCard: {
      summarySectionTitle: '(ES) Summary',
      customizeSectionTitle: '(ES) Customized card',
      to: '(ES) To',
      from: '(ES) From',
      total: '(ES) Total',
      goalCard: '(ES) GoalCard<span>®</span>',
      enterAmount: '(ES) Enter the amount',
      enterGoalCardAmount: '(ES) Enter the amount for your GoalCard®',
      goalCardLabel: '(ES) GoalCard®',
      amountLimit: '(ES) The limit for a single transaction is $200',
      formInputs: {
        yourName: {
          label: '(ES) Your Name',
          placeholder: '(ES) Enter your Name',
        },
        emailAddress: {
          label: '(ES) Your Email Address',
          placeholder: '(ES) Enter your Email Address',
        },
        date: {
          label: '(ES) Date',
          placeholder: '(ES) MM/DD/YYYY',
        },
        whichGoalsTitle: '(ES) Choose which goals to support',
        specialMessage: {
          label: '(ES) Want to add a special message?',
          placeholder: '(ES) Enter your Message',
        },
        checkBoxInputs: {
          howToSupport: {
            title: '(ES) Choose how to support',
            giveSpecificGoal: "(ES) Give to {{name}}'s specific goal(s)",
            letChoose: '(ES) Let {{name}} choose how to spend their GoalCard®',
          },
          when: {
            title: '(ES) When should we send the card?',
            rightNow: '(ES) Right now!',
            specificDate: '(ES) On a specific date',
          },
        },
        formErrors: {
          name: {
            empty: '(ES) Enter a Name',
            wrong: '(ES) Can only contain letters',
          },
          email: {
            empty: '(ES) Enter an Email Address',
            wrong: '(ES) Enter a valid Email Address',
          },
          date: {
            empty: '(ES) Enter a Date',
            wrong: '(ES) Enter a valid Date',
          },
        },
      },
      addPhoto:
        '(ES) Add some spice with a photo or video (optional)<br><span>Valid format:</span>  png, jpg, or jpeg, mp4.',
      emptyGoalCardError:
        '(ES) You cannot send a $0 GoalCard®. Enter a valid amount an try again.',
    },
    purchase: {
      title: "(ES) You're almost done.",
      orderSummary: '(ES) Order Summary',
      orderPayment: '(ES) Order Payment',
      cardTotal: '(ES) Card total',
      serviceFee: '(ES) Goalsetter® service fee',
      submitPayment: '(ES) SUBMIT PAYMENT',
      formInputs: {
        cardHolder: {
          label: '(ES) Cardholder full name',
          placeholder: '(ES) Enter Cardholder full name',
        },
        cardNumber: {
          label: '(ES) Card details (debit or credit)',
          placeholder: '(ES) Enter Card number',
        },
        mmyy: {
          placeholder: 'MM/YY',
        },
        cvc: {
          placeholder: 'CVC',
        },
        zipCode: {
          placeholder: '(ES) Zip Code',
        },
        formErrors: {
          cardHolder: {
            empty: '(ES) Enter Cardholder Name',
            wrong: '(ES) Can only contain letters',
          },
          cardNumber: {
            empty: '(ES) Enter a Card Number',
            wrong: '(ES) Enter a valid Card Number',
          },
          mmyy: {
            empty: '(ES) Enter a date',
            wrong: '(ES) Incorrect date',
          },
          cvc: {
            empty: '(ES) Enter CVC number',
            wrong: '(ES) Incorrect CVC',
          },
          zipCode: {
            empty: '(ES) Enter a Zip Code',
            wrong: '(ES) Incorrect Zip Code',
          },
        },
      },
      stripeErrorModal: {
        balanceInsufficient: {
          description:
            '(ES) Your card has insufficient funds. Please select a new payment method.',
          buttonLabel: '(ES) TRY WITH ANOTHER CARD',
        },
        authenticationFailure: {
          description: '(ES) Please select a new payment method.',
          buttonLabel: '(ES) RETRY WITH ANOTHER CARD',
        },
        unsopported: {
          description: '(ES) Your card does not support this type of purchase.',
          buttonLabel: '(ES) RETRY WITH ANOTHER CARD',
        },
        generic: {
          description:
            '(ES) Your payment could not be processed. Please try again.',
          buttonLabel: '(ES) TRY AGAIN',
        },
      },
      terms:
        '(ES) By making a purchase, you accept the <span>Stripe Terms of Service</span>',
    },
    profile: {
      title: '(ES) Profile',
      buttonLabel: '(ES) <parent>GIVE A GOALCARD<span>{{reg}}</span></parent>',
      goalNotFound: {
        title: '(ES) Goal Not Found',
        description:
          "(ES) We couldn't find the goal you are looking for. But don't worry, you can still send a Goalcard®.",
      },
      profileDetailCard: {
        isSavingFor: '(ES) is saving for',
        giveToGoal: '(ES) GIVE TO GOAL',
        savedToward: '(ES) Saved toward goal',
        noEndDate: '(ES) No end date',
        supportButtonLabel: '(ES) SUPPORT THIS GOAL',
        beTheFirst: '(ES) Be the first supporter!',
        goalFacts: '(ES) Goal Facts:',
        goalFactsDescription:
          "(ES) $1 billion was wasted on gifcards last year alone. Aren't you happy you can give a GoalCard® instead?",
      },
    },
    notFound: {
      title: '(ES) Uh-oh...',
      subtitle: "(ES) We couldn't find the page you are looking for.",
      troubleshooting:
        "(ES) We're troubleshooting this issue on our end. Thank you for your patience as we work on finding a solution.",
      inTheMeantime:
        '(ES) In the meantime, please feel free to reach out to us at <span>{{email}}</span> with questions or suggestions.',
    },
    animatedGoalCard: {
      goalcard: '(ES) GOALCARD',
      goalcardTo: '(ES) To',
      gotMoney: "(ES) You've Got Money!",
      goalcardGreeting: '(ES) Hi there {{name}},',
      goalcardFrom: '(ES) From',
      goalcardFor: '(ES) For',
    },
    printGoalcard: {
      to: '(ES) To',
      from: '(ES) From',
      for: '(ES) For',
      yourGoals: '(ES) YOUR GOALS',
      hi: '(ES) Hi',
      whatIs: '(ES) WHAT IS GOALSETTER?',
      descriptionTitle:
        '(ES) Goalsetter is the best savings and gifting platform to help kids save for their dreams.',
      howToRedeem: '(ES) HOW TO REDEEM:',
      goalcardReceivetoTitle: "(ES) You'll receive a copy of your Goalcard to ",
      goalcardSentToTitle: "(ES) We've sent a copy of your Goalcard to ",
      goalcardClaimInfo:
        '(ES) . Just click on the card and set up a Goalsetter account to redeem the Goalcard and start saving towards your dreams.',
      goalcardClaimInfo2:
        '(ES)  on {{executeAtDate}}. When you receive it, just click on the card and set up a Goalsetter account to redeem the Goalcard and start saving towards your dreams.',
      gotMoney: "(ES) You've Got Money!",
      replayBtn: '(ES) REPLAY',
      claimBtn: '(ES) CLAIM GIFT',
      printBtn: '(ES) PRINT',
    },
    downloadAppModal: {
      title: '(ES) Download the Goalsetter app and start saving today!',
      subtitle:
        '(ES) In order to claim this Goalcard, <span>please scan this QR</span> code to download the app. Goalsetter is available for both iOS and Android phones and tablets.',
    },
  },
};
