import styled from '@emotion/styled';
import { Breakpoints, Colors } from 'goalsetter-webapp-components';

export const ContainerBackground = styled.div`
  background-color: ${Colors.PRIMARY_DS};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  @media (max-width: ${Breakpoints.sm}px) {
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-width: 1450px;
  padding: 48px 192px 88px 192px;
  height: 100%;
  width: 100%;
  @media (max-width: 1024px) {
    padding: 48px 128px;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    padding: 48px 16px;
  }
`;
export const ContentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  gap: 24px;
  @media (max-width: ${Breakpoints.sm}px) {
    flex-direction: column;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 88px;
  @media (max-width: ${Breakpoints.sm}px) {
    padding-bottom: 48px;
  }
`;

export const StyledGsLogo = styled.div`
  width: 147px;
  height: 40px;
  cursor: pointer;
  @media (max-width: ${Breakpoints.sm_portal}px) {
    max-width: 150px;
    max-height: 32px;
  }
`;

export const BgImage1 = styled.div`
  position: absolute;
  width: 1402px;
  height: 307px;
  left: 0px;
  top: 98px;
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const BgImage2 = styled.div`
  width: 422.087px;
  height: 393px;
  flex-shrink: 0;
  @media (max-width: 1024px) {
    width: 216px;
    height: 201.115px;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    width: 100%;
    height: auto;
  }
`;

export const Title = styled.div`
  padding-bottom: 16px;
  color: ${Colors.WHITE};
  font-family: Inter;
  font-size: 56px;
  font-style: normal;
  font-weight: 800;
  line-height: 64px;
  letter-spacing: -1.12px;
  @media (max-width: 1024px) {
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -0.96px;
  }
`;

export const SubTitle = styled.div`
  padding-bottom: 24px;
  color: ${Colors.WHITE};
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 56px;
  letter-spacing: -0.96px;
  @media (max-width: 1024px) {
    font-size: 32px;
    line-height: 48px;
    letter-spacing: -0.64px;
  }
`;

export const Paragraph = styled.div`
  color: ${Colors.WHITE};
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.18px;
  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.16px;
  }
`;

export const ParagraphLink = styled.a`
  color: ${Colors.ORANGE};
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.18px;
`;
