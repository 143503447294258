import styled from 'styled-components';
import { Breakpoints, Colors } from 'goalsetter-webapp-components';

export const Container = styled.div`
  background-color: ${Colors.BLUE_INVESTMENTS};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const RowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  width: 864px;
  @media (max-width: ${Breakpoints.sm_portal}px) {
    width: 90%;
  }
`;

export const FirstRowContainer = styled.div`
  width: 100%;
  justify-content: flex-start;
  border-bottom: 1px solid ${Colors.BLUE_LIGHT};
  padding: 8px 0;
  display: flex;
  flex-wrap: wrap;
  white-space: break-spaces;
`;

export const firstRowButtonStyles = { paddingRight: '24px' };
export const secondRowStyles = {
  width: '100%',
  padding: '16px 0',
  gap: '12px',
};
export const iconStyles = { paddingLeft: '16px', width: 'auto' };

export const linkStyle = {
  textDecoration: 'none',
};

export const iconLinkStyle = {
  textDecoration: 'none',
};
