import { Breakpoints, Colors } from 'goalsetter-webapp-components';
import styled from '@emotion/styled';
import { Flex } from '@mantine/core';

export const Container = styled(Flex)`
  width: 100%;
  height: 100%;
  flex-direction: row;
  @media (max-width: ${Breakpoints.sm_portal}px) {
    flex-direction: column;
  }
`;

export const innerContainerStyle = (
  isMobile?: boolean,
): React.CSSProperties => ({
  width: isMobile ? '100%' : '50%',
  height: isMobile ? '50%' : '100%',
});

export const firstSectionContainerStyle = (
  isMobile?: boolean,
): React.CSSProperties => ({
  width: isMobile ? 'auto' : '50%',
  height: isMobile ? '50%' : 'auto',
  background: Colors.WHITE,
  margin: '15px',
});

export const SecondContainer = styled.div`
  padding: 15px;
`;

export const breakWordStyle = (isMobile?: boolean): React.CSSProperties => ({
  wordWrap: 'break-word',
  width: isMobile ? '100%' : 'auto',
});

export const gotMoneyContainerStyle = {
  background: Colors.BLUE_LIGHT,
  width: '100%',
  height: '100%',
};

export const imageWrapperStyle = {
  width: '100%',
  height: '100%',
  maxWidth: '300px',
  paddingTop: '10px',
};

export const imageStyle = {
  width: '100%',
  display: 'block',
  height: 'auto',
  paddingTop: '15px',
};

export const imgStyle = {
  width: '50%',
  display: 'block',
  maxWidth: '250px',
  height: 'auto',
  paddingTop: '15px',
};

export const gotMoneyTextStyle = {
  width: '100%',
  textAlign: 'center',
} as React.CSSProperties;

export const bottomPaddingStyle = { paddingBottom: '10px' };

export const fontFamilyStyle = {
  fontFamily: 'Gloria Hallelujah, cursive',
};
