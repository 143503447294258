import styled from '@emotion/styled';
import Images from 'assets/img/Images';
import { Breakpoints, Colors } from 'goalsetter-webapp-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 64px;
  width: 100%;
  height: 100%;
  padding-bottom: 24px;
  @media (max-width: ${Breakpoints.sm_portal}px) {
    width: 90%;
    padding-top: 16px;
  }
`;

export const GoalCardBackground = styled.div`
  background-image: url(${Images.GOALCARD});
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100vw;
  @media (max-width: ${Breakpoints.sm_portal}px) {
    background-image: none;
    width: 100vw;
  }
`;

export const StyledProfileInfoContainer = styled.div<{
  isLoading?: boolean;
  hasNoGoals?: boolean;
}>`
  visibility: ${(props) => (props.isLoading ? 'hidden' : 'visible')};
  display: flex;
  padding-top: ${(props) => (props.hasNoGoals ? '40px' : '80px')};
  justify-content: center;
  @media (max-width: ${Breakpoints.sm_portal}px) {
    padding-top: 40px;
    transform: none;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
`;

export const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 16px;
  @media (max-width: ${Breakpoints.sm_portal}px) {
    padding-top: 16px;
    padding-left: 0;
    width: 100%;
    align-items: center;
  }
`;

export const GoalsContainer = styled.div<{ length?: number }>`
  width: 100%;
  max-width: 1041px;
  padding-top: 63px;
  display: grid;
  grid-template-columns: repeat(${(p) => p.length}, 0.33fr);
  justify-content: center;
  align-items: center;
  grid-gap: 16px;
  @media (max-width: ${Breakpoints.sm_portal}px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledCard = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px 8px 0;
  background: ${Colors.MEDIUM_EMPHASIS};
  min-height: 64px;
  border-radius: 1rem;
  margin-top: 40px;
  width: 100%;
  max-width: 864px;
`;

export const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
`;

export const subtitleStyle = (isMobile?: boolean) => ({
  textAlign: isMobile ? 'center' : 'left',
  paddingBottom: isMobile ? '24px' : '0',
});

export const buttonStyle = (isMobile?: boolean) => ({
  height: '48px',
  width: '100%',
  maxWidth: isMobile ? 'none' : '172px',
  background: Colors.ORANGE,
  fontSize: '14px',
  fontFamily: 'Avenir',
  fontWeight: 900,
  borderRadius: '8px',
});

export const goalCardDetailContainerStyle = {
  paddingTop: '40px',
  width: '100%',
  maxWidth: '600px',
};
