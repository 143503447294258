import styled from 'styled-components';
import { Breakpoints, Colors } from 'goalsetter-webapp-components';

export const Container = styled.div`
  background-color: ${Colors.PRIMARY_DS};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1056px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin: 20px 192px 8px 192px;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 16px 0;
  gap: 16px;
  @media (max-width: 1024px) {
    padding: 20px 128px 8px 128px;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    padding: 32px;
    flex-direction: column;
  }
`;

export const iconStyles = { paddingLeft: '16px', width: 'auto' };

export const iconLinkStyle = {
  textDecoration: 'none',
};

export const FooterText = styled.div`
  color: ${Colors.WHITE};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;
