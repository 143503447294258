import { AxiosPromise } from 'axios';
import ApiBase from './ApiBase';
import { CreateGoalCardPayload, RestType } from 'types/api';
import { GoalCardsByIdResponse } from 'types/goalcard';

class GoalcardService extends ApiBase {
  getGoalcard = async (goalcardId: string): Promise<GoalCardsByIdResponse> => {
    const url = `${ApiBase.getBaseUrl()}/GoalcardsMin/${goalcardId}`;
    return ApiBase.makeCallUnsecure(url);
  };

  getAffiliateGoalcard = async (
    affiliateCode: string,
  ): Promise<GoalCardsByIdResponse> => {
    const url = `${ApiBase.getBaseUrl()}/affiliates/${affiliateCode}/goalcard`;
    return ApiBase.makeCallUnsecure(url);
  };

  getContactAffiliateGoalcard = async (
    contactAffiliateCode: string,
  ): Promise<GoalCardsByIdResponse> => {
    const url = `${ApiBase.getBaseUrl()}/AffiliateContacts/${contactAffiliateCode}/goalcard`;
    return ApiBase.makeCallUnsecure(url);
  };

  createGoalcard = async (
    payload: CreateGoalCardPayload,
  ): AxiosPromise<CreateGoalCardPayload> => {
    const options = {
      method: RestType.POST,
      body: JSON.stringify(payload),
    };

    const url = `${ApiBase.getBaseUrl()}/Web-Goalcards`;
    return ApiBase.makeCallUnsecure(url, options);
  };
}

const goalcardService = new GoalcardService();
export default goalcardService;
