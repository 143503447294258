import { Routes, Route } from 'react-router-dom';
// import SendGift from '../app/pages/sendGoalcard/SendGift';
// import SelectCard from '../app/pages/sendGoalcard/SelectCard';
import MainLayout from 'app/layouts/mainLayout';
import OldLayout from 'app/layouts/oldLayout';
// import SendInvitation from 'app/pages/invite/SendInvitation';
// import CustomizeCard from 'app/pages/sendGoalcard/CustomizeCard';
// import SentSuccess from 'app/pages/invite/SentSuccess';
// import SentGoalCardSuccess from 'app/pages/sendGoalcard/SentGoalCardSuccess';
import ResetPassword from '../app/pages/managePassword/ResetPassword';
import ForgetPassword from '../app/pages/managePassword/ForgetPassword';
import ResetPasswordSuccess from '../app/pages/managePassword/ResetPasswordSuccess';
// import Purchase from 'app/pages/sendGoalcard/Purchase';
import { RoutesGs } from './RoutesList';
import Profile from 'app/pages/profile';
import NotFound from 'app/pages/notFound';
import AnimatedGoalCard from 'app/pages/goalcard';
// import Login from 'app/pages/Login';
import UnderConstruction from 'app/pages/underConstruction';
import UnderConstructionLayout from 'app/layouts/underConstructionLayout';

const project = process.env.REACT_APP_PROJECT_TYPE;
const userSubPath = project === 'user' ? '/user' : '/';
const shouldRenderUserScreens = project === 'user' || project === 'portal';

const Router = () => {
  return (
    <Routes>
      <Route
        path={'/'}
        element={<UnderConstructionLayout />}
      >
        <Route
          index
          element={<UnderConstruction />}
        />
        {/* <Route
          index
          element={<SendGift />}
        />
        <Route
          path={RoutesGs.SendGift}
          element={<SendGift />}
        />
        <Route
          path={RoutesGs.CustomizeGoalcard}
          element={<CustomizeCard />}
        />
        <Route
          path={RoutesGs.PurchaseGoalcard}
          element={<Purchase />}
        />
        <Route
          path={RoutesGs.SelectGoalcard}
          element={<SelectCard />}
        />
        <Route
          path={RoutesGs.SendInvitation}
          element={<SendInvitation />}
        />
        <Route
          path={RoutesGs.SuccessInvitation}
          element={<SentSuccess />}
        />
        <Route
          path={RoutesGs.SuccessGoalcard}
          element={<SentGoalCardSuccess />}
        />
        <Route
          path="*"
          element={<NotFound />}
        /> */}
        <Route
          index
          path="*"
          element={<UnderConstruction />}
        />
      </Route>
      {shouldRenderUserScreens ? (
        <Route
          path={userSubPath}
          element={<OldLayout />}
        >
          <Route
            path="*"
            element={<NotFound />}
          />
          <Route
            path={RoutesGs.ResetPassword}
            element={<ResetPassword />}
          />
          <Route
            path={RoutesGs.ForgotPassword}
            element={<ForgetPassword />}
          />
          <Route
            path={RoutesGs.SuccessPassword}
            element={<ResetPasswordSuccess />}
          />
        </Route>
      ) : null}
      {shouldRenderUserScreens ? (
        <Route
          path={userSubPath}
          element={<MainLayout />}
        >
          <Route
            path={RoutesGs.UserId}
            element={<Profile />}
          />
          <Route
            path={RoutesGs.UserGoalId}
            element={<Profile />}
          />
          <Route
            path={RoutesGs.GoalcardAnimation}
            element={<AnimatedGoalCard />}
          />
        </Route>
      ) : null}
      <Route
        path="*"
        element={<NotFound />}
      />
    </Routes>
  );
};

export default Router;
