import styled from '@emotion/styled';
import { Progress } from '@mantine/core';
import { Colors } from 'goalsetter-webapp-components';

export const Separator = styled(Progress)<{ value?: number }>`
  height: ${(props) => (props.value ? '3px' : '0.5px')};
  margin: 8px 0;
`;

export const goalCardStyle = (singleCard?: boolean) => ({
  width: '100%',
  maxHeight: singleCard ? 'none' : '216px',
});

export const cursorPointer = { cursor: 'pointer' };
export const paddingLeft = { paddingLeft: '8px' };
export const giveToGoalBtnStyle = {
  width: '125px',
  height: '36px',
  margin: '0',
  cursor: 'pointer',
};
export const fontWeight = { fontWeight: 400 };
export const giveToGoalBtnStyleMobile = {
  height: '36px',
  margin: '0',
  cursor: 'pointer',
  marginTop: '24px',
};
export const bottomBannerStyle = {
  background: Colors.GRAY_LIGHT,
  padding: '12px 8px',
  borderRadius: '8px',
  marginTop: '16px',
};
export const supportButtonStyle = { padding: '8px 0', cursor: 'pointer' };
