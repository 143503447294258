import {
  differenceInMilliseconds,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isSameYear,
  format,
  add,
  sub,
  isValid,
} from "date-fns";

export type UNITS =
  | "years"
  | "months"
  | "weeks"
  | "days"
  | "hours"
  | "minutes"
  | "seconds";
export type FORMATS =
  | "MM/YYYY"
  | "YYYY-MM-DD"
  | "MM/DD"
  | "MM/DD/YY"
  | "MMMM DD, YYYY"
  | "MM/DD/YYYY";
export const INVALID_DATE = "invalid_date";

type GsDate = {
  date: Date;
  getAll: () => void;
  isAfter: (dateToCompare: GsDate) => boolean;
  isBefore: (dateToCompare: GsDate) => boolean;
  isSame: (dateToCompare: GsDate) => boolean;
  add: (unit: UNITS, amount: number) => GsDate;
  subtract: (unit: UNITS, amount: number) => GsDate;
  format: (selectedFormat: FORMATS) => string;
  differenceInMilliseconds: (dateToCompare: GsDate) => number;
};

export const GsDate = (inputDate?: Date | string): GsDate => {
  let date: Date;

  if (!inputDate) {
    date = new Date();
  } else {
    if (typeof inputDate === "string") {
      date = new Date(inputDate);
    } else {
      date = inputDate as Date;
    }
  }

  return {
    date: date,
    getAll: () => {
      console.table({
        toDateString: date.toDateString(),
        toISOString: date.toISOString(),
        toJSON: date.toJSON(),
        toLocaleDateString: date.toLocaleDateString(),
        toLocaleString: date.toLocaleString(),
        toLocaleTimeString: date.toLocaleTimeString(),
        toString: date.toString(),
        toTimeString: date.toTimeString(),
        toUTCString: date.toUTCString(),
      });
    },
    isSame: (dateToCompare: GsDate): boolean => {
      return (
        isSameDay(date, dateToCompare.date) &&
        isSameMonth(date, dateToCompare.date) &&
        isSameYear(date, dateToCompare.date)
      );
    },
    isAfter: (dateToCompare: GsDate) => {
      const dateL = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );
      const dateR = new Date(
        dateToCompare.date.getFullYear(),
        dateToCompare.date.getMonth(),
        dateToCompare.date.getDate()
      );
      return isAfter(dateL, dateR);
    },
    isBefore: (dateToCompare: GsDate) => {
      const dateL = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );
      const dateR = new Date(
        dateToCompare.date.getFullYear(),
        dateToCompare.date.getMonth(),
        dateToCompare.date.getDate()
      );
      return isBefore(dateL, dateR);
    },
    add: (unit: UNITS, amount: number) => {
      const addedDate = add(date, { [unit]: amount });
      return GsDate(addedDate);
    },
    subtract: (unit: UNITS, amount: number) => {
      const addedDate = sub(date, { [unit]: amount });
      return GsDate(addedDate);
    },

    format: (selectedFormat: FORMATS): string => {
      if (!isValid(date)) {
        return INVALID_DATE;
      }
      const FORMATS = {
        "MM/YYYY": () => format(date, "MM/yyyy"),
        "YYYY-MM-DD": () => format(date, "yyyy-MM-dd"),
        "MM/DD": () => format(date, "MM/dd"),
        "MM/DD/YY": () => format(date, "MM/dd/yy"),
        "MMMM DD, YYYY": () => format(date, "MMMM dd, yyyy"),
        "MM/DD/YYYY": () => format(date, "MM/dd/yyyy"),
      };
      return FORMATS[selectedFormat]();
    },
    differenceInMilliseconds: (dateToCompare: GsDate): number => {
      if (!isValid(date) || !isValid(dateToCompare.date)) {
        return -1;
      }
      return Math.abs(differenceInMilliseconds(date, dateToCompare.date));
    },
  };
};
