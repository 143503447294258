import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import Router from './router';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { AppContainer } from './style';

// i18n
import { I18nextProvider } from 'react-i18next';
import i18n from 'localization/i18n';
import { AppManagement } from './utils/appManagement';
import { SENTRY_ENV } from 'utils/environment';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptyFunction = () => {};
const container = document.getElementById('root')!;
const root = createRoot(container);
AppManagement.initApp(
  emptyFunction,
  emptyFunction,
  '1.0.0',
  '1.0.0',
  '1.0.0',
  SENTRY_ENV,
  'web',
  '1.0.0',
  '1.0.0',
);
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <AppContainer>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </AppContainer>
      </Provider>
    </I18nextProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
