import styled from '@emotion/styled';
import Images from 'assets/img/Images';
import { Breakpoints, Colors, Input } from 'goalsetter-webapp-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 64px;
  width: 864px;
  padding-bottom: 24px;
  @media (max-width: ${Breakpoints.sm_portal}px) {
    width: 90%;
    padding-top: 16px;
  }
`;

export const GoalCardBackground = styled.div`
  background-image: url(${Images.GOALCARD});
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100vw;
  @media (max-width: ${Breakpoints.xs}px) {
    background-image: none;
    width: 100vw;
  }
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 225px;
  padding-top: 32px;
  @media (max-width: ${Breakpoints.sm_portal}px) {
    padding-top: 32px;
    height: 100px;
    justify-content: flex-start;
  }
`;

export const StyledTitleLeftCol = styled.div`
  width: 70%;
`;

export const StyledTitleRightCol = styled.div`
  width: 30%;
`;

export const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
`;

export const StyledInput = styled(Input)`
  padding: 24px 0;
  & .mantine-Input-input {
    border: 2px solid ${(props) => (props.error ? Colors.RED : Colors.GRAY)};
    color: ${Colors.BLACK};
  }
  & [data-invalid] {
    border: 2px solid ${Colors.RED} !important;
    color: ${Colors.BLACK};
  }
  & .mantine-TextInput-error {
    display: none;
  }
`;

export const StyledCard = styled.div<{
  fullWidth?: boolean;
  marginTop?: boolean;
}>`
  display: flex;
  align-items: center;
  padding: ${(props) => (props.fullWidth ? '8px 16px 8px 0' : '8px 16px')};
  background: ${Colors.MEDIUM_EMPHASIS};
  min-height: 64px;
  border-radius: 1rem;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  margin-top: ${(props) => (props.marginTop ? '40px' : 'none')};
`;

export const titleContainerStyle = { width: '100%' };
export const titleStyle = (isMobile: boolean) => ({
  paddingTop: isMobile ? '0px' : '56px',
});
export const mainTitleStyle = {
  textAlign: 'center' as const,
  marginBottom: '24px',
  marginTop: '24px',
};
export const subtitleStyle = (isMobile: boolean) => ({
  fontWeight: 400,
  padding: isMobile ? '10px 0' : '24px 0px',
});
export const inviteLabelStyle = {
  paddingTop: '56px',
  paddingRight: '8px',
  width: '100%',
};
export const inviteButtonStyle = {
  margin: '0px',
  padding: '0px',
  minWidth: '130px',
};

export const inviteLabelTextStyle = (isMobile: boolean) => ({
  maxWidth: isMobile ? '50%' : '100%',
});

export const paddingLeft = { paddingLeft: '8px' };

export const MobileTitleContainer = styled.div`
  margin-top: 36px;
  margin-bottom: 24px;
  text-align: center;
`;
