import React from 'react';
import { Flex } from '@mantine/core';
import { t } from 'i18next';
import { Icon, IconName } from 'goalsetter-webapp-components';
import {
  Container,
  ContentContainer,
  FooterText,
  RowContainer,
  iconLinkStyle,
  iconStyles,
} from './styles';
import { Link } from 'react-router-dom';

export const FooterUnderConstruction: React.FC = () => (
  <Container>
    <ContentContainer>
      <RowContainer>
        <FooterText>
          {t('footer.rights', { year: new Date().getFullYear() })}
        </FooterText>
        <Flex>
          <Link
            to={String(process.env.REACT_APP_INSTAGRAM)}
            target="_blank"
            style={iconLinkStyle}
            data-testid="linkInstagram"
          >
            <Icon
              icon={IconName.INSTAGRAM}
              size={24}
            />
          </Link>
          <Link
            to={String(process.env.REACT_APP_TWITTER)}
            target="_blank"
            style={iconLinkStyle}
            data-testid="linkTwitter"
          >
            <Icon
              icon={IconName.TWITTER}
              size={24}
              style={iconStyles}
            />
          </Link>
          <Link
            to={String(process.env.REACT_APP_FACEBOOK)}
            target="_blank"
            style={iconLinkStyle}
            data-testid="linkFacebook"
          >
            <Icon
              icon={IconName.FACEBOOK}
              size={24}
              style={iconStyles}
            />
          </Link>
          <Link
            to={String(process.env.REACT_APP_EMAIL)}
            target="_blank"
            style={iconLinkStyle}
            data-testid="linkEmail"
          >
            <Icon
              icon={IconName.EMAIL}
              size={24}
              style={iconStyles}
            />
          </Link>
        </Flex>
      </RowContainer>
    </ContentContainer>
  </Container>
);
