import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RoutesGs } from '../../../../router/RoutesList';

const ForgetPassword = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(RoutesGs.ResetPassword, {
      state: {
        hash: searchParams.get('hash'),
      },
    });
  }, []);

  return <div></div>;
};

export default ForgetPassword;
