const Images = {
  BG_INTRO: require('./bg_intro.png'),
  BG_INTRO_PASSWORD: require('./backgroundPassword.png'),
  GOALCARD: require('./goalCard.png'),
  SEND_GOALCARD: require('./SendGoalCard.png'),
  QR: require('./qrDownload.png'),
  DOWNLOAD_APPLE: require('./download-apple.png'),
  DOWNLOAD_GOOGLE: require('./download-google.png'),
  PASSWORD_USER: require('./resetPass.png'),
  PASSWORD_USER_SUCCESS: require('./resetSuccess.png'),
  YOU_GOT_MONEY: require('./you-got-money-icon.png'),
  LOGO_PRINT: require('./logo_print.png'),
  BG_PRINT: require('./background_print.png'),
  LOADER_JSON: require('./loader-hexagon.json'),
  UNDER_CONSTRUCTION_BG_1: require('./underConstructionBg1.png'),
  UNDER_CONSTRUCTION_BG_2: require('./underConstructionBg2.png'),
};

export default Images;
