import styled from '@emotion/styled';
import Images from 'assets/img/Images';
import { Breakpoints, Colors } from 'goalsetter-webapp-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 64px;
  width: 100%;
  height: 100%;
  padding-bottom: 24px;
  @media (max-width: ${Breakpoints.sm_portal}px) {
    width: 90%;
    padding-top: 16px;
  }
`;

export const GoalCardBackground = styled.div`
  background-image: url(${Images.GOALCARD});
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100vw;
  @media (max-width: ${Breakpoints.sm_portal}px) {
    background-image: none;
    width: 100vw;
  }
`;

export const StyledLink = styled.a`
  color: ${Colors.ORANGE};
  text-decoration: none;
  font-weight: bold;
`;

export const textContainerStyle = {
  maxWidth: '864px',
  width: '100%',
  paddingTop: '39px',
};

export const paddingBottom = { paddingBottom: '40px' };
export const paddingTop = { paddingTop: '24px' };
