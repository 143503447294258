import { Outlet } from 'react-router-dom';
import { MainContainer, ContentContainer } from './styles';
import FooterUnderConstruction from 'app/components/FooterUnderConstruction';

const UnderConstructionLayout = () => {
  return (
    <MainContainer id="main-container">
      <ContentContainer>
        <Outlet />
        <FooterUnderConstruction />
      </ContentContainer>
    </MainContainer>
  );
};

export default UnderConstructionLayout;
