import * as React from 'react';
import {
  BgImage1,
  BgImage2,
  Container,
  ContainerBackground,
  ContentContainer,
  LogoContainer,
  Paragraph,
  ParagraphLink,
  StyledGsLogo,
  SubTitle,
  Title,
} from './styles';
import { Link } from 'react-router-dom';
import { Colors, GoalsetterLogo } from 'goalsetter-webapp-components';
import { Flex, Image } from '@mantine/core';
import Images from 'assets/img/Images';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

export const UnderConstruction: React.FC = () => {
  return (
    <ContainerBackground>
      <BgImage1>
        <Image src={Images.UNDER_CONSTRUCTION_BG_1} />
      </BgImage1>
      <Container>
        <LogoContainer>
          <Link
            target="_blank"
            to="/"
          >
            <StyledGsLogo>
              <GoalsetterLogo color={Colors.WHITE} />
            </StyledGsLogo>
          </Link>
        </LogoContainer>
        <ContentContainer>
          <Flex
            direction="column"
            justify="center"
            style={{ width: '100%' }}
          >
            <Title>{t('underConstruction.title')}</Title>
            <SubTitle>{t('underConstruction.subtitle')}</SubTitle>
            <Paragraph>
              <Trans
                i18nKey={t('underConstruction.description')}
                components={{
                  span: <ParagraphLink href="mailto:hello@goalsetter.co" />,
                }}
              />
            </Paragraph>
          </Flex>
          <BgImage2>
            <Image src={Images.UNDER_CONSTRUCTION_BG_2} />
          </BgImage2>
        </ContentContainer>
      </Container>
    </ContainerBackground>
  );
};
