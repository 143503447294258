import { v4 as uuidv4 } from 'uuid';
import axios, {
  AxiosProgressEvent,
  AxiosRequestConfig,
  AxiosResponse,
  CancelTokenSource,
} from 'axios';
import Base64 from 'utils/base64';

import {
  URL_WITHOUT_PREVIOUS_AUTH,
  ENDPOINTS_WHICH_IGNORE_FORBIDDEN,
} from 'utils/constants';
import {
  FETCH_TIMEOUT,
  RequestOptions,
  RestType,
  VerboseResponse,
} from 'types/api';
import { AppManagement } from 'utils/appManagement';
import { API_APP_VERSION } from '../utils';

export const axiosInstance = axios.create();

export enum ConnectionErrorExceptions {
  noInternet = 'TypeError: Network request failed',
  noInternetAxios = 'Network Error',
  maxWaitingRetries = 'Maximum waiting times for token refresh reached',
}

enum HttpBrazeHeaders {
  brazeEnabled = '1',
}

export const getBrazeHttpHeaders = () => {
  return { 'x-engagementEventsActive': HttpBrazeHeaders.brazeEnabled };
};

class ApiBase {
  static version = AppManagement.getVersion();

  static build = AppManagement.getBuildNumber();

  static authToken = '';

  static refreshToken = '';

  static refreshTokenExpiration: string;

  static tokenRefresherInterval: any = null;

  static authIsRefreshing = false;

  static interceptorsInitialized = false;

  static url401Counter = 0;

  static baseUrl: string;

  static quizBaseUrl: string;

  static investmentUrl: string;

  static triviaUrl: string;

  /** *****************************
            Token Management
  ****************************** */

  static parseJwt = (token: string) => {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url?.replace('-', '+').replace('_', '/');
      return JSON.parse(Base64.atob(base64));
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  /** *****************************
            URL MANAGEMENT
  ****************************** */

  static urlRequireAuth(url: string) {
    return ApiBase.checkIfEndpointDoesntExistsInUrlArray(
      url,
      URL_WITHOUT_PREVIOUS_AUTH,
    );
  }

  static urlIsForbidden(url: string) {
    return ApiBase.checkIfEndpointDoesntExistsInUrlArray(
      url,
      ENDPOINTS_WHICH_IGNORE_FORBIDDEN,
    );
  }

  static checkIfEndpointDoesntExistsInUrlArray(
    url: string,
    arrayEndpoint: string[],
  ) {
    for (let i = 0; i < arrayEndpoint.length; i += 1) {
      const context = arrayEndpoint[i];
      if (url.indexOf(context) !== -1 && url.endsWith(context)) return false;
    }
    return true;
  }

  static getUrl(url: string) {
    let _url = url;
    return _url;
  }

  /** *****************************
            INTERCEPTORS
  ****************************** */

  static initInterceptors = () => {
    if (ApiBase.interceptorsInitialized) {
      return;
    }
    ApiBase.interceptorsInitialized = true;
    axiosInstance.interceptors.response.use(ApiBase.successInterceptor);
  };

  static successInterceptor = (response: AxiosResponse<any, any>) => response;

  static getGsErrorCode = (responseError: any) =>
    responseError?.response?.data?.['status-code'];

  /** *****************************
            Headers
  ****************************** */
  static createHeaders() {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      // Version: 6.5,
      BuildNumber: API_APP_VERSION,
      // OperativeSystem: AppManagement.operativeSystem,
      // rev: `${AppManagement.getMinorVersion()}`,
      uuid: uuidv4(),
      // deviceId: AppManagement.getDeviceId(),
      ...getBrazeHttpHeaders(),
    };
    return headers;
  }

  constructor() {
    ApiBase.initInterceptors();
  }

  static getBaseUrl = () => {
    return AppManagement.getBaseUrl();
  };

  /* *****************************
            AXIOS CANCEL
  ****************************** */

  static getCancelTokenSource = () => {
    const source = axios.CancelToken.source();
    return source;
  };

  static cancelRequest = (cancelTokenSource: CancelTokenSource) => {
    if (typeof cancelTokenSource !== typeof undefined) {
      cancelTokenSource.cancel('Cancel Request');
    }
  };

  /* *****************************
            AXIOS CALLS
  ****************************** */

  /**
   *
   * @param url
   * @param opts
   * @returns
   * @description only for when the endpoint will return a
   *  json and doesnt requiere user to be log in
   */
  static async makeCallUnsecure(
    url: string,
    opts: RequestOptions = {},
    parameters: { timeout: number } = { timeout: FETCH_TIMEOUT },
  ): Promise<any> {
    const _opts: RequestOptions = { timeout: parameters?.timeout, ...opts };
    try {
      const response = await ApiBase.makeAxiosCall(url, _opts);
      console.log('makeCallUnsecure: ', response);
      if (response?.data) {
        return response?.data;
      } else {
        return 'OK';
      }
    } catch (error) {
      const _error = ApiBase.errorWrapper(error);
      throw _error;
    }
  }

  static createVerboseObject = (
    response: AxiosResponse | undefined,
  ): VerboseResponse => {
    const verboseResponse = {
      response: response?.data,
      headers: response?.headers,
      statusCode: response?.status,
    };
    return verboseResponse;
  };

  async makeCallVerboseUnsecure(
    url: string,
    opts: RequestOptions = {},
    parameters: { timeout: number } = { timeout: FETCH_TIMEOUT },
  ) {
    const _opts: RequestOptions = { timeout: parameters?.timeout, ...opts };
    try {
      const response = await ApiBase.makeAxiosCall(url, _opts);
      console.log('makeCallVerboseUnsecure: ', response);
      return ApiBase.createVerboseObject(response);
    } catch (error) {
      const _error = ApiBase.errorWrapper(error);
      throw _error;
    }
  }

  static makeCallGeneric = async (
    url: string,
    opts: RequestOptions = {},
  ): Promise<any> => ApiBase.makeAxiosCall(url, opts);

  static errorWrapper = (error?: any) => {
    if (error?.message === ConnectionErrorExceptions.noInternetAxios) {
      return ConnectionErrorExceptions.noInternet;
    }
    const statusCode = error?.response?.status;
    if (statusCode) {
      return {
        ...error,
        statusCode,
        status: statusCode,
      };
    }
    return error;
  };

  static makeAxiosCall = (url: string, opts: RequestOptions = {}) => {
    const _url = ApiBase.getUrl(url);
    const method = opts.method ?? RestType.GET;
    const timeout = opts.timeout ?? FETCH_TIMEOUT;
    const headers: any = ApiBase.createHeaders();
    const config: AxiosRequestConfig<any> = {
      url: _url,
      method,
      data: opts.body,
      timeout,
      headers,
      cancelToken: opts.cancelTokenSource?.token,
    };
    return axiosInstance(config);
  };

  static uploadFileWithProgress = (
    url: string,
    file: string | Blob,
    onProgress: (progress: number) => void,
  ): Promise<string> => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file);

      axios
        .post(url, formData, {
          onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            if (!progressEvent || !progressEvent.total) return;
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            onProgress(progress);
          },
        })
        .then((response: AxiosResponse<string>) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}
export default ApiBase;
