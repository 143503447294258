import React from 'react';
import { t } from 'i18next';
import { Colors, Text, Typography } from 'goalsetter-webapp-components';
import { useMediaQueryGs } from 'app/hooks/useMediaQuery';
import {
  Container,
  GoalCardBackground,
  StyledLink,
  paddingBottom,
  paddingTop,
  textContainerStyle,
} from './styles';
import { Trans } from 'react-i18next';
import { Flex } from '@mantine/core';
import LogoHeader from 'app/components/LogoHeader';

export const NotFound: React.FC = () => {
  const { isMobile } = useMediaQueryGs();

  return (
    <GoalCardBackground>
      <Container>
        <LogoHeader />
        <Flex
          direction="column"
          style={textContainerStyle}
        >
          <Text
            type={isMobile ? Typography.GSH3 : Typography.GSH1}
            color={Colors.WHITE}
            style={paddingBottom}
          >
            {t('notFound.title')}
          </Text>
          <Text
            type={Typography.GSH6}
            color={Colors.WHITE}
          >
            {t('notFound.subtitle')}
          </Text>
          <Text
            type={Typography.GSBODY2}
            color={Colors.WHITE}
            style={paddingTop}
          >
            {t('notFound.troubleshooting')}
          </Text>
          <Text
            type={Typography.GSBODY2}
            color={Colors.WHITE}
            style={paddingTop}
          >
            <Trans
              i18nKey={t('notFound.inTheMeantime')}
              values={{
                email: 'hello@goalsetter.co',
              }}
              components={{
                span: <StyledLink href="mailto:hello@goalsetter.co" />,
              }}
            />
          </Text>
        </Flex>
      </Container>
    </GoalCardBackground>
  );
};
