import { AxiosPromise } from 'axios';
import { ActiveGoalsEntity, FamilyMember } from 'types/goals';
import ApiBase from './ApiBase';
import { Investor, RestType } from 'types/api';

class UserService extends ApiBase {
  changePassword = async (
    hash: string,
    newPassword: string,
  ): AxiosPromise<unknown> => {
    const options = {
      method: RestType.PATCH,
      body: JSON.stringify({ newPassword }),
    };
    const url = `${ApiBase.getBaseUrl()}/users/${hash}/password`;
    return ApiBase.makeCallUnsecure(url, options);
  };

  getPublicInformationByUsername = async (
    userName: string,
  ): AxiosPromise<FamilyMember[]> => {
    const url = `${ApiBase.getBaseUrl()}/familyMembers?query=${userName}&pageIndex=1`;
    return ApiBase.makeCallUnsecure(url);
  };

  getPublicInformationBySearch = async (
    searchValue: string,
    index: number,
  ): AxiosPromise<unknown> => {
    const url = `${ApiBase.getBaseUrl()}/FamilyMembers?query=${encodeURIComponent(
      searchValue,
    )}&pageIndex=${index}`;
    return ApiBase.makeCallUnsecure(url);
  };

  getPublicGoals = async (
    userId: string,
  ): AxiosPromise<ActiveGoalsEntity[]> => {
    const url = `${ApiBase.getBaseUrl()}/users/${userId}/publicGoals`;
    return ApiBase.makeCallUnsecure(url);
  };

  getPublicInvestors = async (userId: string): AxiosPromise<Investor[]> => {
    const url = `${ApiBase.getBaseUrl()}/users/${userId}/PublicInvestors`;
    return ApiBase.makeCallUnsecure(url);
  };
}

const userService = new UserService();
export default userService;
