import ApiBase from './ApiBase';

class PublicService extends ApiBase {
  postFile = async (file: any, progressCallback: any): Promise<string> => {
    const url = `${ApiBase.getBaseUrl()}/MediaFiles/Videos`;
    return ApiBase.uploadFileWithProgress(url, file, progressCallback);
  };
}

const publicService = new PublicService();
export default publicService;
