import { AxiosPromise } from 'axios';
import ApiBase from './ApiBase';
import { RestType } from 'types/api';

class PublicService extends ApiBase {
  postDownloadAppSMS = async (
    phoneNumberInfo: unknown,
  ): AxiosPromise<unknown> => {
    const options = {
      method: RestType.POST,
      body: JSON.stringify({ phoneNumberInfo }),
    };
    const url = `${ApiBase.getBaseUrl()}/DownloadLinkBySMS`;
    return ApiBase.makeCallUnsecure(url, options);
  };
}

const publicService = new PublicService();
export default publicService;
