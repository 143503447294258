import styled from '@emotion/styled';
import Images from 'assets/img/Images';
import { Breakpoints } from 'goalsetter-webapp-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 64px;
  width: 100%;
  padding-bottom: 24px;
  @media (max-width: ${Breakpoints.sm_portal}px) {
    width: 90%;
    height: 100%;
    padding-top: 16px;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 64px;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

export const FlipContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 900px;
  height: 450px;
  @media (max-width: ${Breakpoints.sm_portal}px) {
    width: 100%;
    height: 100%;
  }
`;

export const GoalCardBackground = styled.div<{ height: number }>`
  background-image: url(${Images.GOALCARD});
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  width: 100vw;
  height: 100vh;
  min-height: ${(props) => props.height + 400}px;
  @media (max-width: ${Breakpoints.lg}px) {
  }
  @media (max-width: ${Breakpoints.xs}px) {
    background-image: none;
    width: 100vw;
  }
`;

export const PrintGoalcardContainer = styled.div`
  display: none;
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
`;
