import { Modal } from '@mantine/core';
import { Breakpoints } from 'goalsetter-webapp-components';
import styled from '@emotion/styled';

export const StyledModal = styled(Modal)`
  & .mantine-Paper-root {
    position: relative;
    min-width: fit-content;
    max-width: fit-content;
    max-height: fit-content;
    overflow-y: unset;
    @media (max-width: ${Breakpoints.sm_portal}px) {
      transform: scale(0.75) !important;
    }
  }
  & .mantine-Modal-inner {
    padding: 0px;
  }
`;

export const innerContainerStyle = { maxWidth: '635px' };
export const verticalPaddingStyle = { padding: '32px 0' };

export const closeButtonStyle = {
  position: 'absolute',
  top: '20px',
  right: '20px',
  cursor: 'pointer',
  zIndex: 20,
} as React.CSSProperties;

export const textAlignCenterStyle = {
  textAlign: 'center',
} as React.CSSProperties;

export const StyledBoldText = styled.text`
  font-weight: bold;
`;
