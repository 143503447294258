import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface LanguageState {
  language: 'en' | 'es';
}

const initialState: LanguageState = {
  language: 'en',
};

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    changeLanguage: (state, action: PayloadAction<'en' | 'es'>) => {
      state.language = action.payload;
    },
  },
});

export const selectedLanguage = (state: RootState) => state.language.language;

export const { changeLanguage } = languageSlice.actions;
export default languageSlice.reducer;
