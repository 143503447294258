export const ENV = process.env.REACT_APP_ENV;
export const SENTRY_ENV = process.env.REACT_APP_API_CORE_URL;
export const BASE_QUIZZES_API = process.env.REACT_APP_API_SITE_URL;
export const BASE_API = process.env.REACT_APP_CARD_IMAGEBUCKET_URL;
export const BASE_INVESTMENT = process.env.REACT_APP_STRIPE_KEY;
export const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
export const LANDINGS_URL = process.env.REACT_APP_LANDINGS_URL;
export const API_APP_VERSION = process.env.REACT_APP_API_APP_VERSION;
export const API_APP_VERSION_REVISION =
  process.env.REACT_APP_API_APP_VERSION_REVISION;
export const DOWNLOAD_APP_LINK = process.env.REACT_APP_DOWNLOAD_APP_LINK;
export const REACT_APP_GIVEGOALCARDS = process.env.REACT_APP_GIVEGOALCARDS;
