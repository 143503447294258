import { Colors, GsLogo } from 'goalsetter-webapp-components';
import { LogoContainer, StyledGsLogo } from './styles';
import { Link } from 'react-router-dom';

export const LogoHeader: React.FC = () => (
  <LogoContainer>
    <Link
      target="_blank"
      to="/"
    >
      <StyledGsLogo>
        <GsLogo color={Colors.WHITE} />
      </StyledGsLogo>
    </Link>
  </LogoContainer>
);
