import styled from 'styled-components';
import Images from '../../assets/img/Images';
import { Breakpoints } from 'goalsetter-webapp-components';

export const MainContainer = styled.main`
  background-image: url(${Images.BG_INTRO});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  @media (max-width: ${Breakpoints.xs}px) {
    width: 100vw;
  }
`;

export const OldContainer = styled.main`
  background-image: url(${Images.BG_INTRO_PASSWORD});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  @media (max-width: ${Breakpoints.xs}px) {
    width: 100vw;
  }
`;

export const ContentContainer = styled.div`
  background: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

export const HeaderContainer = styled.div`
  margin-top: 26px;
  margin-bottom: 40px;
`;
