export const defaultRetryOpts = {
  timer: 0,
  q: 0,
  is401: false,
  count401: 0,
};

export const FETCH_TIMEOUT = 90000;

export const MAX_REFRESH_TIME_ITERATIONS = 3;
