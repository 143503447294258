import { AxiosPromise } from 'axios';
import ApiBase from './ApiBase';
import { CardsEntity } from 'types/goalcard';

class CardService extends ApiBase {
  getCards = async (): AxiosPromise<CardsEntity[]> => {
    const url = `${ApiBase.getBaseUrl()}/Cards`;
    return ApiBase.makeCallUnsecure(url);
  };
}

const cardService = new CardService();
export default cardService;
