import { useMediaQuery } from '@mantine/hooks';
import { isMobileSizes, isTabletSizes } from '../utils/mediaQuery';

export const useMediaQueryGs = () => {
  const isMobile = useMediaQuery(isMobileSizes);
  const isTablet = useMediaQuery(isTabletSizes);

  return {
    isMobile,
    isTablet,
  };
};
