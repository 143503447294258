import styled from '@emotion/styled';
import { Breakpoints } from 'goalsetter-webapp-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 64px;
  width: 864px;
  padding-bottom: 24px;
  @media (max-width: ${Breakpoints.sm_portal}px) {
    width: 90%;
    padding-top: 16px;
  }
`;

export const ImageContainer = styled.div`
  width: 330px;
  height: 105px;
  padding-bottom: 20px;
  @media (max-width: ${Breakpoints.sm_portal}px) {
    width: auto;
    max-height: 82px;
    margin-left: 16px;
  }
`;

export const DownloadContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  img {
    height: 65px !important;
    width: 216px !important;
    margin-right: 20px;
    margin-left: 20px;
  }
  @media (max-width: ${Breakpoints.sm_portal}px) {
    flex-direction: column;
    img {
      margin-bottom: 10px;
    }
  }
`;

export const HeaderCard = styled.div`
  width: '100%';
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const titleStyle = {
  width: '100%',
  textAlign: 'center' as const,
};

export const descriptionStyle = {
  width: '100%',
  textAlign: 'left' as const,
  marginTop: '30px',
};

export const cardStyle = {
  width: '100%',
  marginTop: '64px',
};
