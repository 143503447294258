export enum RestType {
  POST = 'POST',
  GET = 'GET',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
  PUT = 'PUT',
}

export enum ApiBaseErrorType {
  NEEDS_TO_REFRESH_TOKEN = 'NEEDS_TO_REFRESH_TOKEN',
  NEEDS_TO_RESTART_APP = 'NEEDS_TO_RESTART_APP',
  DO_NOTHING = 'DO_NOTHING',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  MAX_REFRESHING_TIMES = 'MAX_REFRESHING_TIMES',
  IS_REFRESHING = 'IS_REFRESHING',
}
