import { Breakpoints } from 'goalsetter-webapp-components';

const isMobileSizes = `(max-width: ${Breakpoints.sm_portal - 1}px)`;

const isTabletSizes =
  `(min-width: ${Breakpoints.sm_portal}px)` &&
  `(max-width: ${Breakpoints.md - 1}px)`;

const isDesktopSizes = `(min-width: ${Breakpoints.md}px)`;

export { isMobileSizes, isTabletSizes, isDesktopSizes };
