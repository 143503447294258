import {
  RestartInterface,
  RestartWithLogoutInterface,
} from 'types/appManagement';

export class AppManagement {
  private static mayorVersion: string;
  private static mediumVersion: string;
  private static minorVersion: string;
  private static buildNumber: string;
  private static deviceId: string;
  private static baseUrl: string | undefined;

  static operativeSystem: string;
  static restart: RestartInterface;
  static restartWithLogout: RestartWithLogoutInterface;

  static initApp = (
    restart: RestartInterface,
    restartWithLogout: RestartWithLogoutInterface,
    mayorVersion: string,
    mediumVersion: string,
    minorVersion: string,
    baseUrl: string | undefined,
    operativeSystem: string,
    buildNumber: string,
    deviceId: string,
  ) => {
    AppManagement.restart = restart;
    AppManagement.operativeSystem = operativeSystem;
    AppManagement.restartWithLogout = restartWithLogout;
    AppManagement.mayorVersion = mayorVersion;
    AppManagement.mediumVersion = mediumVersion;
    AppManagement.minorVersion = minorVersion;
    AppManagement.buildNumber = buildNumber;
    AppManagement.deviceId = deviceId;
    AppManagement.baseUrl = baseUrl;
  };

  static setUrls = (baseUrl: string) => {
    AppManagement.baseUrl = baseUrl;
  };

  static getVersion = () => {
    return `${AppManagement.mayorVersion}.${AppManagement.mediumVersion}`;
  };
  static getMinorVersion = () => {
    return AppManagement.minorVersion;
  };
  static getMediumVersion = () => {
    return AppManagement.minorVersion;
  };
  static getMayorVersion = () => {
    return AppManagement.minorVersion;
  };
  static getBuildNumber = () => {
    return AppManagement.buildNumber;
  };
  static getDeviceId = () => {
    return AppManagement.deviceId;
  };
  static getBaseUrl = () => {
    return AppManagement.baseUrl;
  };
}
