import { AxiosPromise } from 'axios';
import ApiBase from './ApiBase';
import { GoalCardInvitation, RestType } from 'types/api';

class GoalService extends ApiBase {
  getSupportersByGoalId = async (goalId: string): AxiosPromise<unknown> => {
    const url = `${ApiBase.getBaseUrl()}/Goals/${goalId}/publicSupporters?page=0&pageSize=1000`;
    return ApiBase.makeCallUnsecure(url);
  };

  sendGoalCardInvite = async (
    goalCardInvitationData: GoalCardInvitation,
  ): AxiosPromise<unknown> => {
    const {
      senderName,
      receiverName,
      receiverEmail,
      receiverPhoneNumber,
      captchaToken,
    } = goalCardInvitationData;
    const options = {
      method: RestType.POST,
      body: JSON.stringify({
        senderName,
        receiverName,
        receiverEmail,
        receiverPhoneNumber,
        captchaToken,
      }),
    };
    const url = `${ApiBase.getBaseUrl()}/send-goalcard-invite`;
    return ApiBase.makeCallUnsecure(url, options);
  };
}

const goalService = new GoalService();
export default goalService;
