export const SECOND_MILLIS = 1000;
export const MINUTE_MILLIS = 60 * SECOND_MILLIS;
export const HOUR_MILLIS = 60 * MINUTE_MILLIS;
export const DAY_MILLIS = 24 * HOUR_MILLIS;
export const WEEK_MILLIS = 7 * DAY_MILLIS;

export const IDLE_TIMEOUT_MILLIS = 15 * MINUTE_MILLIS;
export const IDLE_TIMEOUT_MILLIS_BEFORE_RESTART = 2 * SECOND_MILLIS;
export const REFRESHER_MINUTES = 14 * MINUTE_MILLIS;
export const MAX_RETRY_ON_TOKEN_REFRESH = 3;
export const WAITING_TIME_FOR_TOKEN_REFRESH = 5 * SECOND_MILLIS;
export const URL_WITHOUT_PREVIOUS_AUTH = [
  'Auth/Credentials',
  'Auth/Refresh',
  'Security/ResetPassword',
  'Security/Token',
  '/Parents',
];

export const ENDPOINTS_WHICH_IGNORE_FORBIDDEN = [
  '/AppVersion',
  '/ApiConfiguration',
  '/ApiConfiguration?',
  '/Nonce',
  '/NonRootedDeviceVerification',
  '/NotifyErrorOnProviderCall',
  '/NonRootedDeviceVerification',
];

export const FAILED_TIMES_LIVE = 5;
export const FAILED_TIMES = 20;
export const TIME_BETWEEN_GET_MILLIS = 3000;

export const MIN_LENGTH_NAME = 2;

export const DEFAULT_SPENDING_PERIOD = 'lastWeek';
export const DEFAULT_SAVING_TYPE = 'All';
export const DEFAULT_SAVING_PERIOD = 'lastWeek';

export const RESEND_CODE_TIME = 45;

export const PRIVACY_POLICY_FILENAME = 'privacypolicy.pdf';
export const S3_URL_PRIVACY_POLICY =
  'https://s3.amazonaws.com/goalsetter.public.prod/public/privacypolicy.pdf';
export const CARD_POLICY_FILENAME = 'casholaPrivacypolicy.pdf';
export const S3_URL_CARD_POLICY =
  'https://s3.amazonaws.com/goalsetter.public.prod/public/cardPrivacy.pdf';

export const POLICIES_PRIVACY = 'https://policies.google.com/privacy';
export const POLICIES_TERMS = 'https://policies.google.com/terms';

export const STATEMENT_FILENAME = 'statement';
export const STATEMENT_EXTENSION = '.pdf';

export const AFFILIATE_CODE_TYPES = {
  GC_AFFILIATE_FREE_STOCKS: 6,
  GC_AFFILIATE_LOCKED_CODE: 5,
  GC_AFFILIATE_SPONSORED: 4,
  GC_AFFILIATE_REPEATABLE_CODE: 3,
  GC_AFFILIATE_ASSIGNABLE_GOALCARD: 7,
  GC_AFFILIATE_UNIQUE_CODE: 2,
  NA: 1,
};

/** Investing */

export const MIN_ORDER_PERCENTAGE = 0.03;
export const USELESS_CONST_FOR_MIT_VALUE_FOR_KID = 5;

export const EVERY_WEEK = 1;
export const EVERY_MONTH = 2;
export const BALANCE_DROP = 3;

export const EVERY_WEEK_VALUE = 'Weekly';
export const EVERY_MONTH_VALUE = 'Monthly';
export const BALANCE_DROP_VALUE = 'BalanceRelated';
export const MAX_WARNING_DAY_TRADE = 4;
export const MIN_ORDER_REMAIN_STOCK = 0.0001;
export const MIN_REMAIN_MONEY = 0.05;

// GLOBAL CONFIG
export const GOALCARD_ACH = 0;
export const REPLACEMENT_CASHOLA = 8;
export const REFERRALS_LIMIT = 5;

export const SECURITY_ATTESTATION_TIMEOUT = 5000;
export const SECURITY_GOOGLE_UPDATE_TIMEOUT = 5000;

export const CONFFETI_COLORS = ['#fce18a', '#ff726d', '#b48def', '#f4306d'];

export const TERMS_AND_CONDITIONS_PDF = '/files/terms-and-conditions.pdf';

export const STATE_NAMES = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const STATE_VALUES = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];
