import styled from '@emotion/styled';
import { Breakpoints } from 'goalsetter-webapp-components';

export const StyledGsLogo = styled.div`
  width: 206px;
  height: 44px;
  cursor: pointer;
  @media (max-width: ${Breakpoints.sm_portal}px) {
    max-width: 150px;
    max-height: 32px;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
`;
